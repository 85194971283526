@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.gradient {
    --size: 300px;
    /* Smaller default size for mobile */
    --speed: 10s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 6));
    /* Reduce blur for smaller screens */
    background-image: linear-gradient(#4377ef, #7befd0);
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    position: absolute;
    z-index: 0;
}

@media (min-width: 720px) {
    .gradient {
        --size: 300px;
        /* Larger size for tablets */
        filter: blur(calc(var(--size) / 5));
    }
}

@media (min-width: 1024px) {
    .gradient {
        --size: 350px;
        /* Full size for desktops */
        filter: blur(calc(var(--size) / 5));
    }
}

.container {
    position: relative;
    inset: 0;
    display: flex;
    place-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    background-color: #333333;

}